import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { replaceUnicode } from "../utils/utils"
// import { replace } from "formik"

export const CoverFeatured = ({
  img,
  label,
  title,
  text,
  btnText,
  btnLink,
}) => {
  return (
    <>
      {typeof img === "string" ? (
        <div
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="cover cover-featured"
        >
          <div className="container">
            <div className="cover-content">
              <span className="cover-label">{label && label}</span>
              <h2 className="cover-title">{title && title}</h2>
              {text && (
                <p
                  className="cover-text"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></p>
              )}
              <Link
                to={(btnLink && btnLink) || "/"}
                className="btn btn-link fx-underline text-white"
              >
                {btnText && btnText}
              </Link>
            </div>
          </div>
          <div className="bg-overlay bg-overlay-40" />
        </div>
      ) : (
        <BackgroundImage
          Tag="section"
          fluid={img}
          className="cover cover-featured"
        >
          <div className="container">
            <div className="cover-content">
              <span className="cover-label">{label && label}</span>
              <h2 className="cover-title">{title && title}</h2>
              <p
                className="cover-text"
                dangerouslySetInnerHTML={{ __html: text }}
              ></p>
              <Link to={(btnLink && btnLink) || "/"}>
                <button className="btn btn-link fx-underline text-white">
                  {btnText && btnText}
                </button>
              </Link>
            </div>
          </div>
          <div className="bg-overlay bg-overlay-40" />
        </BackgroundImage>
      )}
    </>
  )
}
