import React from "react"

export const AcfIconList = ({ data }) => {
  return (
    <div className="values">
      <div className="row">
        {data.innerBlocks
          .find(x => x.name === "acf/iconlist")
          .acf.list.map((item, i) => (
            <div key={`${item.title}-${i}`} className="col-lg-6">
              <div className="value-item">
                <img alt="img" src={item.icon && item.icon.sourceUrl} />
                <div className="values-wrapper">
                  <p className="h5 pb-2">{item.title}</p>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
